@tailwind base;
@tailwind components;
@tailwind utilities;
 

@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    font-family: 'Reddit Mono', 'Roboto', 'sans';
}
.fc {
    width: 100%;
    height: 500px;
    overflow-y: auto;
}



/* Custom styles.css */

  /* .rbc-btn {
    color: var(--primary-color); /* Use CSS variables or direct color values */

  
  /* .rbc-today {
    background-color: #eaf6ff;
  }
  
  .rbc-event {
    background-color: #3174ad;
    color: white;
    border-radius: 5px;
    border: none;
  }
  
  .rbc-header {
    background-color: #f5f5f5;
    color: #333;
    padding: 5px 10px;
    border-bottom: 2px solid #dedede;
  }

  .rbc-month-header {
    display: flex;
  } */
  /* .rbc-month-row {
    display: flex;
    width: 100%;
  }
.rbc-row{
    display: flex;
    width: 100%;
}
.rbc-date-cell{
    width: 100px;
} */

